import React, { useRef } from "react";
import { Box, Container, Grid } from "@mui/material";

import { useParallax } from "react-scroll-parallax";

import Slider from "react-slick";
// // Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../components/css/slick.css";

import SectionHeading from "../../components/SectionHeading";
import "./css/teamsection.css";

import { HomePageData } from "../../Data";

const HomeTeam = () => {
  const teamMembers = HomePageData.TeamSection;

  const parallax = useParallax({
    speed: 15,
    translateX: [0, 100, "easeInOut"],
  });

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    fade: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleLinkClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <Box component="section" sx={{ py: 7, position: 'relative' }}  bgcolor="background.bg_3">
        <Container>
          <Box className="teamSectionHeadingWrp">
            <Grid container>
              <Grid item md={4}></Grid>
              <Grid item md={5}>
                <Box className="teamSectionHeading">
                  <SectionHeading
                    title={teamMembers.title}
                    subtitle={teamMembers.subTitle}
                    description={teamMembers.description}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Slider className="" {...settings} ref={sliderRef}>
            <Box className="teamWrp">
              {teamMembers.Team.slice(0, 3).map((team) => {
                return (
                  <Box className="teamItem" key={team.id}>
                    <div className="teamInner">
                      <div className="team__img">
                        <Box
                          component="img"
                          className="img-fluid"
                          alt={team.name}
                          src={team.image}
                        />
                      </div>
                      <h2 className="team__title">{team.name}</h2>
                      <h3 className="team__role">{team.position}</h3>
                      <Box className="team__linkedIn">
                        <Box
                          component="a"
                          href={team.linkedIn}
                          onClick={handleLinkClick}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn
                        </Box>
                      </Box>
                    </div>
                  </Box>
                );
              })}
            </Box>
            <Box className="teamWrp">
              {teamMembers.Team.slice(3, 6).map((team) => {
                return (
                  <Box className="teamItem" key={team.id}>
                    <div className="teamInner">
                      <div className="team__img">
                        <Box
                          component="img"
                          className="img-fluid"
                          alt={team.name}
                          src={team.image}
                        />
                      </div>
                      <h2 className="team__title">{team.name}</h2>
                      <h3 className="team__role">{team.position}</h3>
                    </div>
                  </Box>
                );
              })}
            </Box>
          </Slider>
          <Box className="HeroTeam" ref={parallax.ref}>
            <Box
              component="img"
              className="img-fluid"
              alt="Chase"
              src={teamMembers.Chase.image}
            />
            <Box className="customTooltip">
              <svg
                className="tooltip__shape"
                width="100%"
                height="100%"
                viewBox="0 0 400 300"
              >
                <path d="M 199,21.9 C 152,22.2 109,35.7 78.8,57.4 48,79.1 29,109 29,142 29,172 45.9,201 73.6,222 101,243 140,258 183,260 189,270 200,282 200,282 200,282 211,270 217,260 261,258 299,243 327,222 354,201 371,172 371,142 371,109 352,78.7 321,57 290,35.3 247,21.9 199,21.9 Z"></path>
              </svg>
              <h3>{teamMembers.Chase.title}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: teamMembers.Chase.content }}
              ></p>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default HomeTeam;
