import { Box, Button, Container } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// import { useLayoutEffect, useRef } from "react";

import "./css/homeportfolio.css";
import SectionHeading from "../../components/SectionHeading";
import { HomePageData } from "../../Data";
import { Link } from "react-router-dom";

const PortfolioHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const homeWorkData = HomePageData.HomePortfolio;

  const work_item_imageStyle = {
    rotate: "-10deg",
    scale: "0.85",
    y: "100px",
    opacity: "0",
  };

  return (
    <>
      <Box
        className="portfolio__section"
        component="section"
        sx={{ py: 7 }}
        bgcolor="background.bg_3"
      >
        <Container>
          <SectionHeading
            title={homeWorkData.title}
            subtitle={homeWorkData.subTitle}
            align="center"
          />
          <div className="portfolio__list-1">
            {homeWorkData.portfolio.map((work_item) => (
              <motion.div
                initial={work_item_imageStyle}
                whileInView={{ rotate: 0, x: 0, y: 0, scale: 1, opacity: 1 }}
                transition={{
                  type: "spring",
                  duration: 2,
                  repeatType: "reverse",
                }}
                className="portfolio__item"
                key={work_item.id}
              >
                <a href={work_item.webURL} target="_blank" rel="noreferrer">
                  <img
                    alt={work_item.title}
                    src={work_item.imgURL}
                    className="img-fluid"
                  />
                  <div className="portfolio__info">
                    <h3 className="portfolio__title">{work_item.title}</h3>
                    <div className="portfolio__tag">
                      {work_item.tag.map((tag, index) => (
                        <Box key={index}>
                          {tag}
                          <span>,</span>
                        </Box>
                      ))}
                    </div>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
          <Box align={"center"}>
            <Button
              sx={{
                mt: isMobile ? 4 : 20,
                align: "center",
              }}
              variant="roundBtn"
              component={Link}
              to={'/portfolio'}
              endIcon={<EastIcon />}
            >
            And the Journey Continues..
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PortfolioHome;
