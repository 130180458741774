import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import HeaderBgImg2 from "../images/project_3.jpg";
import { motion } from "framer-motion";
import { ParallaxBanner } from "react-scroll-parallax";
import { Box, Container, Typography } from "@mui/material";
import Breadcrumbs from '@mui/material/Breadcrumbs';

import './css/pageheader.css'

const titleVariants = {
  hidden: { opacity: 0, y: -100 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -100 },
};

const PageHeader = ({ title, backgroundImg }) => {
  return (
    <Box className="page-header" position="relative" zIndex={1}>
      <Container>
        <ParallaxBanner
          layers={[
            { image: backgroundImg, speed: -20 },
            {
              speed: -15,
            },
          ]}
          className="aspect-img"
        />
        <Box className="pageHeader_content">
          <motion.h1
            variants={titleVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ type: "spring", duration: 1 }}
          >
            {title}
          </motion.h1>
          <motion.div
            variants={titleVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ type: "spring", duration: 1.2 }}
            className="breadcrumbWrp"
          >
           
         
          <Breadcrumbs aria-label="breadcrumb" align="center">
            <Link underline="hover" color="inherit" to="/">
            Home
            </Link>
            <Typography color="text.primary">{title}</Typography>
          </Breadcrumbs>
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
};

PageHeader.defaultProps = {
  backgroundImg: HeaderBgImg2,
  title: "Page Title",
};

export default PageHeader;
