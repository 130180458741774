import "./App.css";
import React, {useEffect} from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "./ThemeModule";
import AnimatedCursor from "react-animated-cursor";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import SinglePost from "./pages/blog/SinglePost";
import ServiceDetailsLayout from "./pages/services/ServiceDetailsLayout";
import SeoContent from "./pages/services/SeoContent";
import WebDesignContent from "./pages/services/WebDesignContent";
import ServicePage from "./pages/ServicePage";
import Consultation from "./pages/Consultation";
import Page404 from "./pages/Page404";
import ComingSoon from "./pages/ComingSoon";


const services = [
  {
    id: "0",
    slug: "web-design",
    title: "Web Design",
    content: <WebDesignContent />,
  },
  {
    id: "1",
    slug: "seo",
    title: "SEO",
    content: <SeoContent />,
  },
];

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={0}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: "var(--cursor-color)",
          }}
          outerStyle={{
            border: "3px solid var(--cursor-color)",
          }}
        />
        <div className="body-bg"></div>
        <BrowserRouter> 
        <ScrollToTop />

          <Header />
          <div className="mainContentWrp">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/about-us" element={<AboutUs />} />
              <Route exact path="/contact-us" element={<Contact />} />
              <Route exact path="/portfolio" element={<Portfolio />} />
              <Route exact path="/blog" element={<Blog />} />
              <Route exact path="/services" element={<ServicePage />} />
              <Route exact path="/consultation" element={<Consultation />} />
              <Route exact path="/coming-soon" element={<ComingSoon />} />
              <Route path="*" element={<Page404 />} />
              <Route path="/blog/:postId" element={<SinglePost />} />
              {services.map((service) => (
                <Route
                  key={service.id}
                  exact
                  path={`/service/${service.slug}`}
                  element={<ServiceDetailsLayout id={service.id} content={service.content} />}
                />
              ))}
            </Routes>
          </div>
        
        <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
