import React from "react";
// import HomeBanner from "./home/HomeBanner";
import USPSection from "./home/USPSection";
import HomeAbout from "./home/HomeAbout";
import WhyChooseUs from "./home/WhyChoose";
import HomeTeam from "./home/HomeTeam";
import BrandInfo from "./home/BrandInfo";
import HowWeWork from "./home/HowWeWork";
import ProductSection from "./home/ProductSection";
import HeroSection from "./home/HeroSection";
import PortfolioHome from "./home/PortfolioHome";
import ClientLogos from "./services/ClientLogos";
// import HomePortfio from "./home/HomePortfio";
// import TrailImages from "./home/homeBanner/banner";

import { Helmet } from "react-helmet";

import { PortfolioData } from "../Data";

const Home = () => {
  // const clientLogo = PortfolioData.portFolio
  // .filter(portItem => portItem.logoUrl)
  // .map((portItem, index) => ({
  //   img: portItem.logoUrl,
  //   title: portItem.title
  // }));
  // Function to filter, map, and get a random subset of items
  function getRandomClientLogos(data, numItems) {
    const clientLogo = data.portFolio
      .filter((portItem) => portItem.logoUrl) // Filter out items where logoUrl is falsy (blank, null, undefined, empty string)
      .map((portItem) => ({
        img: portItem.logoUrl,
        title: portItem.title,
        pageUrl: portItem.webURL,
      }));

    function getRandomItems(array, numItems) {
      const shuffled = array.sort(() => Math.random() - 0.5);
      return shuffled.slice(0, numItems);
    }

    const randomClientLogos = getRandomItems(clientLogo, numItems);
    return randomClientLogos;
  }

  const clientLogo = getRandomClientLogos(PortfolioData, 30);

  return (
    <>
      <Helmet>
        <title>
          Digital Marketing and Advertising Agency in Salt Lake City Utah, Best
          Website Revamp Agency in Salt Lake City Utah
        </title>
        <meta
          name="description"
          content="ReBrand Gurus is a leading digital creative and marketing agency in Salt Lake City Utah, specializing in SEO, PPC, social media, ORM Services, Web design, online products, mobile application
"
        />
      </Helmet>
      <HeroSection />
      <USPSection />
      <HomeAbout />
      <WhyChooseUs />
      <HomeTeam />
      <BrandInfo />

      <PortfolioHome />
      <ClientLogos clientLogo={clientLogo} />
      <HowWeWork />
      <ProductSection />
    </>
  );
};

export default Home;
