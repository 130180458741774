import { Box, Container } from "@mui/material";
import React from "react";
import { PortfolioData } from "../../Data";
import WorkItem from "./WorkItem";

const WorkGrid = () => {
  return (
    <>
      <Box>
        <Container>
          <Box className="work_grid_container">
            {PortfolioData.portFolio.slice(0,3).map((workItem) => (
              <WorkItem key={workItem.id} item={workItem} />
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default WorkGrid;
