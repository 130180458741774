import { Box } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";

const WorkListItem = ({ portItem, index }) => {
  // const portfImgItemRef = useRef(null);

  // function portfImageCursor(event) {
  //   const portfImgItem = portfImgItemRef.current;
  //   if (!portfImgItem) return;

  //   const contentBox = portfImgItem.getBoundingClientRect();
  //   const dx = event.clientX - contentBox.x;
  //   const dy = event.clientY - contentBox.y;
  //   portfImgItem.children[5].style.transform = `translate(${dx}px, ${dy}px)`;
  // }
  const formattedNumber = String(index + 1).padStart(2, "0");
  const categortClass = portItem.category.toLowerCase();

  

  return (
    <>
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ x: -0, y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        transition={{
          type: "spring",
          duration: 2,
        }}
        className="portfoli_list_item"
      >
        <a
          href={portItem.webURL}
          target="_blank"
          rel="noreferrer"
          // ref={portfImgItemRef}
          // onMouseMove={portfImageCursor}
          className="portfolio__item_inner"
        >
          <Box className="portfolio__no">{formattedNumber}</Box>
          <h5 className="portfolio__title">{portItem.title}</h5>
          <Box className={`portfolio__cat ${categortClass}`}>
            <span>{portItem.category}</span>
          </Box>
          <Box className="portfolio__reviewText">{portItem.reviewText}</Box>
          <Box className="portfolio__tags">
            {portItem.tag.map((p_tag) => (
              <Box key={p_tag}>
                {p_tag}
                <span>,</span>
              </Box>
            ))}
          </Box>
          <div
            className="portfolio__hover"
            style={{
              backgroundImage: `url(${portItem.imgURL})`,
            }}
          ></div>
        </a>
      </motion.div>
    </>
  );
};

export default WorkListItem;
