import React, {  } from "react";

import './css/button.css'
import { Link } from "react-router-dom";

const CustomButton = ({children, endIcon, link, margin}) => {
  return (
    <>
      <Link to={link} style={{margin:margin}} className="button button--atlas">
        <span>{children} {endIcon}</span>
        <div className="marquee" aria-hidden="true">
          <div className="marquee__inner">
            <span>{children}</span>
            <span>{children}</span>
            <span>{children}</span>
            <span>{children}</span>
          </div>
        </div>
      </Link>
    </>
  );
};

export default CustomButton;

CustomButton.defaultProps = {    
  children: "Know More",    
  margin: "34px 0 20px",  
}
