import React from "react";
import './css/packageBox.css'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
const PackageBox = (props) => {
  return (
    <>
      <Link to={props.url} className={`package__item ${ props.className }`}>
        <Box className="package__icon">
          <span>
           <CheckRoundedIcon />
          </span>
        </Box>
        <Box className="package__info">
          <h3 className="package__title">{props.title}</h3>
          <p>{props.description}</p>
        </Box>
        <Box className="package__amount">
        {props.badge ? <h4 className="best-value">{props.badge}</h4> : null}

          
          <p dangerouslySetInnerHTML={{__html: props.price}}></p>
        </Box>
        <Box className="bookingBtn">{props.btnText}</Box>
      </Link>
    </>
  );
};

export default PackageBox;

PackageBox.defaultProps = {    
  title:"14 Days Free",
  description:"Subscription fee is $129.99 USD and automatically renews each year.",
  price:"$129<span> .99</span>",
  // type:"Yearly",
  class:"dark"
}